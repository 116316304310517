import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import Container from "@components/container"
import Layout from "@components/layout"
import StaticHero from "@components/staticHero"
import Section from "@components/section"
import KeyPoints from "@components/keyPoints"
import LearnMore from "@components/learnMore"
import Seo from "@components/seo"
import Callout from "@components/callout"
import PrimaryCta from "@components/PrimaryCta"
import Anchor from "@components/Anchor"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Assets
import xywavColourfulLogoImg from "@images/logos/xywav-logo-large-callout.svg"
import purplePdf from "@images/icons/icon-purple-pdf.svg"
import adultSvg from "@images/side-effects/adult.svg"

const SideEffects = ({ location, data }) => {
  return (
    <Layout location={location} isiBgColour="white" footerBgColour="white">
      <Seo
        title="Understand Possible Side Effects of XYWAV | XYWAV for IH"
        description="Learn more about the possible side effects of XYWAV® (calcium, magnesium, potassium, and sodium oxybates). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero
        eyebrow="Possible Side Effects"
        imageData={data.heroImage}
        alt="Lady playing cards with young boy"
        gradient="purple"
      >
        Understanding possible{" "}
        <span tw="md:whitespace-nowrap xl:whitespace-normal">
          side effects with XYWAV
        </span>
      </StaticHero>

      <section tw="bg-lilac relative py-10 overflow-hidden">
        <img
          src={xywavColourfulLogoImg}
          tw="hidden absolute opacity-50 -top-[75px] -left-[235px] z-10 lg:block"
          alt=""
          width="780"
        />
        <Container>
          <h2 tw="font-cocon font-bold text-merlin text-[24px] mb-8 text-center md:(text-[32px])">
            XYWAV can cause serious side effects, including the following:
          </h2>
          <KeyPoints points={keyPoints} mdWrap />
        </Container>
      </section>

      <section tw="py-8 md:py-14">
        <Container>
          <div tw="grid grid-cols-4 md:(grid-cols-12 mx-auto gap-x-8)">
            <h2 tw="col-span-full font-cocon font-bold leading-tight text-merlin text-[24px] mb-5 order-2 md:(text-[32px] mb-9 order-none)">
              The most common side effects in the XYWAV for Idiopathic
              Hypersomnia clinical study included:
            </h2>
            <img
              src={adultSvg}
              tw="col-span-full mx-auto px-14 mb-7 order-1 md:(col-span-4 mb-0 px-0 order-none)"
              alt="Bigger person next to small person icon"
            />
            <div tw="col-span-full flex flex-wrap text-[15px] mb-7 order-3 md:(col-span-8 text-[18px] pl-8 mb-12 order-none)">
              <div tw="w-1/2">
                <ul tw="col-[1/span 1] pl-[18px] list-disc list-outside">
                  {sideEffectsOne.map((item, i) => {
                    return (
                      <li key={i} tw="mb-3 xl:(mb-4.5)">
                        {item.text}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div tw="w-1/2">
                <ul tw="col-[1/span 1] pl-[18px] list-disc list-outside">
                  {sideEffectsTwo.map((item, i) => {
                    return (
                      <li key={i} tw="mb-3 xl:(mb-4.5)">
                        {item.text}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <p tw="mt-6 w-full md:(pr-16 mt-12) xl:(mt-9)">
                17 out of 154 people (11%) had side effects that made them stop
                taking XYWAV. Anxiety was the most common reason.
              </p>
            </div>
            <h3 tw="col-span-full font-cocon font-bold text-merlin text-[24px] leading-[27px] mb-5 order-4 md:(text-[32px] leading-[36px] mb-6 order-none)">
              These are not all the possible side effects of XYWAV.
            </h3>
            <p tw="col-span-full mb-6 order-5 md:(mb-11 order-none)">
              For more information, ask your doctor or pharmacist. Call your
              doctor for medical advice about side effects. You may report side
              effects to the FDA at{" "}
              <Anchor link="http://www.fda.gov/medwatch" isExternal newTab>
                www.fda.gov/medwatch
              </Anchor>{" "}
              or{" "}
              <Anchor link="tel:1-800-FDA-1088">
                <nobr>1-800-FDA-1088</nobr>
              </Anchor>
              .
            </p>
            <h3 tw="col-span-full font-cocon font-bold text-merlin leading-tight text-[24px] text-center order-6 md:(text-[32px] order-none)">
              In clinical study participants who remained on treatment, side
              effects tended to occur early and diminish over time.
            </h3>
          </div>
        </Container>
      </section>

      <Callout heading="The possible side effects of XYWAV were seen in 2 clinical studies of more than 350 people living with Idiopathic Hypersomnia or narcolepsy (aged 18 to 75)." />

      <section tw="py-8 md:py-16">
        <Container>
          <h2 tw="font-cocon text-merlin font-bold text-[24px] leading-[27px] mb-5 md:(text-[32px] leading-tight mb-12)">
            What you should know about taking XYWAV with other medications:
          </h2>
          <div tw="grid grid-cols-4 md:(grid-cols-12 mx-auto gap-8)">
            <div tw="col-span-full mb-9 md:(block col-span-5 mb-0)">
              <GatsbyImage
                tw="md:(block w-full)"
                image={getImage(data.whatYouShouldKnowImage)}
                alt="Woman on her phone"
              />
            </div>
            <div tw="text-[15px] col-span-full md:(text-[18px] col-start-7 col-span-6)">
              <h3 tw="font-bold mb-4 md:mb-6">
                Tell your doctor about all the medicines you take, including
                prescription and{" "}
                <span tw="whitespace-nowrap">over-the-counter</span> medicines,
                vitamins, and herbal supplements.
              </h3>
              <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-[15px] mb-6 xl:(text-xl leading-[1.4])">
                <li>
                  Especially, tell your doctor if you take other medicines to
                  help you sleep (sedatives).
                </li>
                <li tw="mt-[17px]">
                  Know the medicines you take. Keep a list of them to show your
                  doctor and pharmacist when you get a new medicine.
                </li>
              </ul>
              <p>
                If there are other medications you are currently taking, be sure
                to ask your doctor or pharmacist if XYWAV will interact or
                interfere with how they will work.
              </p>
            </div>
          </div>
        </Container>
      </section>

      <Section bgColour={tw`bg-seashell`}>
        <Container>
          <div tw="grid grid-cols-4 md:(grid-cols-12 mx-auto gap-8)">
            <div tw="order-2 col-span-full md:(order-1 col-span-6 flex flex-col justify-center)">
              <h2 tw="font-cocon font-bold leading-tight text-merlin text-[24px] mb-8 text-center md:(text-[32px] mb-12 text-left)">
                More about XYWAV and what to expect when starting treatment.
              </h2>
              <div tw="flex justify-center md:block">
                <PrimaryCta
                  isExternal
                  noInterstitial
                  url="/pdf/XYWAV_IH_Brochure.pdf"
                >
                  Download Brochure
                </PrimaryCta>
              </div>
            </div>
            <a
              href="/pdf/XYWAV_IH_Brochure.pdf"
              target="_blank"
              tw="hidden order-1 col-span-4 mb-8 mx-auto md:(block mb-0 order-2 col-start-8 col-span-5)"
            >
              <img src={purplePdf} alt="Download PDF brochure icon" />
            </a>
          </div>
        </Container>
      </Section>

      <Callout
        heading="Talk to your doctor about Idiopathic Hypersomnia and XYWAV as a possible treatment option for you."
        cta={{
          text: "Ask About Xywav",
          url: "/idiopathic-hypersomnia/talking-to-your-doctor",
        }}
      />

      <LearnMore ctas={learnMoreCtas} colour="purple" ih />
    </Layout>
  )
}

const keyPoints = [
  {
    title: "Breathing problems",
    text: (
      <div tw="text-[15px] leading-[19.5px] xl:(text-[18px] leading-[25.2px] min-h-[150px])">
        <span tw="block text-left">Including:</span>
        <ul tw="list-disc list-inside text-left [text-indent: -24px] ml-6 mb-8">
          <li>slower breathing</li>
          <li>trouble breathing</li>
          <li>
            and/or short periods of not breathing while sleeping (sleep apnea)
          </li>
        </ul>
        <p tw="block text-left">
          People who already have breathing or lung problems have a higher
          chance of having breathing problems when they use XYWAV.
        </p>
      </div>
    ),
  },
  {
    title: "Mental health problems",
    text: (
      <div tw="text-[15px] leading-[19.5px] xl:(text-[18px] leading-[25.2px] min-h-[150px])">
        <span tw="block text-left">Including:</span>
        <ul tw="list-disc list-inside text-left [text-indent: -24px] ml-6 mb-6">
          <li>confusion</li>
          <li>seeing or hearing things that are not real (hallucinations)</li>
          <li>unusual or disturbing thoughts (abnormal thinking)</li>
          <li>feeling anxious or upset</li>
          <li>depression</li>
          <li>thoughts of killing yourself or trying to kill yourself</li>
          <li>increased tiredness</li>
          <li>feelings of guilt or worthlessness</li>
          <li>difficulty concentrating</li>
        </ul>
        <p tw="block mb-6 text-left">
          Tell your doctor if you have or had depression or have tried to harm
          yourself.
        </p>
        <p tw="block text-left font-bold">
          Call your doctor right away if you have symptoms of mental health
          problems or a change in weight or appetite.
        </p>
      </div>
    ),
  },
  {
    title: "Sleepwalking",
    text: (
      <div tw="text-[15px] leading-[19.5px] xl:(text-[18px] leading-[25.2px] min-h-[150px])">
        <p tw="block text-left">
          XYWAV can cause sleepwalking which can cause injuries. Call your
          doctor if this occurs.
        </p>
      </div>
    ),
  },
]
const sideEffectsOne = [
  {
    text: "Nausea",
  },
  {
    text: "Headache",
  },
  {
    text: "Anxiety",
  },
  {
    text: "Dizziness",
  },
  {
    text: "Insomnia",
  },
  {
    text: "Excessive sweating (hyperhidrosis)",
  },
  {
    text: "Decreased appetite",
  },
]

const sideEffectsTwo = [
  {
    text: "Vomiting",
  },
  {
    text: "Dry mouth",
  },
  {
    text: "Diarrhea",
  },
  {
    text: "Fatigue",
  },
  {
    text: "Sleepiness (somnolence)",
  },
  {
    text: "Tremor",
  },
  {
    text: "Parasomnia",
  },
]

const learnMoreCtas = [
  {
    text: "Learn more about taking XYWAV",
    url: "/idiopathic-hypersomnia/dosing/",
  },
  {
    text: (
      <>
        Get support with JazzCares<sup>®</sup> for XYWAV
      </>
    ),
    url: "/idiopathic-hypersomnia/patient-support-programs/",
  },
]

export default SideEffects

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "what-is-xywav-ih/lady-playing-cards.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    whatYouShouldKnowImage: file(
      relativePath: { eq: "side-effects/image-web-1-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 936
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`
